html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    font-size: 2x;
    font-family: "Lato", sans-serif;
    line-height: 1.5;
    background-color: #eee;
}

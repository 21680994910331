.App {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.Panel {
    width: 100%;
    max-width: 45x;
    padding: 2x;
    background-color: white;
    border-radius: 2x;
    box-shadow: 0 .5x 1.5x rgba(0, 0, 0, .3);

    &.is-hidden {
        display: none;
    }

    &-title {
        margin: 0;
        padding-bottom: 1x;
        font-weight: bold;
        font-size: 1.8rem;
        text-align: center;
    }
}

.Logo {
    margin: 2x 0;
    text-align: center;

    & img {
        max-width: 100%;
    }
}

.Form {
    &-fields {
        display: flex;
        flex-flow: column;
    }
}

.Input {
    margin-bottom: 2x;

    &-field {
        width: 100%;
        padding: 1x 1.5x;
        font-size: 1rem;
        border: 0;
        border-radius: 2x;
        box-shadow: inset 0 0 0 .1x grey;
        transition: box-shadow .2s;

        &:focus {
            outline: none;
            box-shadow: inset 0 0 0 .4x lightblue;
        }

        &--number {
            appearance:textfield; /* Firefox */

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
                appearance: none;
            }
        }
    }
}

.Submit {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1x 2x;
    color: white;
    font-size: 1rem;
    background-color: grey;
    border: 0;
    border-radius: 2x;
    cursor: pointer;

    &:focus, &:hover {
        background-color: dimgrey;
        outline: none;
    }

    &:active {
        background-color: darkgrey;
    }

    &-submitLabel {
        display: block;
    }

    &-loadingLabel {
        display: none;
    }

    &-loadingIcon {
        display: block;
        display: none; /* TODO: decide if use this */
        width: 1.2rem;
        height: 1.2rem;
        border: .4x solid white;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-radius: 50%;
        animation: rotate 1.2s infinite ease-in-out;
    }

    &.is-loading {
        & .Submit-submitLabel {
            display: none;
        }

        & .Submit-loadingLabel {
            display: block;
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(720deg);
    }
}

.Choices {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-around;

    &-button {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 1x 2x;
        color: white;
        line-height: 1;
        text-decoration: none;
        background-color: grey;
        border-radius: 2x;

        &:focus, &:hover {
            background-color: dimgrey;
        }

        &:active {
            background-color: darkgrey;
        }

        &.is-disabled {
            opacity: .7;
            pointer-events: none;
        }

        &-icon {
            font-size: 5rem;
        }

        &-label {
            font-weight: bold;
            font-size: 1.2rem;
        }
    }
}

.Messages {
    margin-bottom: 2x;
    text-align: center;

    & > span {
        display: block;
        margin: .5x 0;
        padding: 0;
        background-color: #bff3ff;
        border-radius: 1x;
    }

    &--errors {
        & > span {
            background-color: #ffc9bf;
        }
    }
}
